import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "@auth0/nextjs-auth0";
import axios from "axios";

const UserContext = createContext();

export const LocalUserProvider = ({ children }) => {
  const { user, error, isLoading } = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user && !isLoading) {
      setUserInfo(null);
    } else if (user && !isLoading) {
      const storage = window.localStorage;
  
      if (!storage.getItem("token")) {
        setLoading(true);
        axios.get("/api/login").then((res) => {
          const userInfo = res.data.data;
          storage.setItem("userDetails", JSON.stringify(userInfo));
          storage.setItem("token", userInfo.token);
          storage.setItem("user", userInfo.accountType.toLowerCase());
  
          setUserInfo({ ...userInfo, token: userInfo.token, user: userInfo });
        }).catch(console.error)
          .finally(() => setLoading(false));
      } else {
        const details = JSON.parse(storage.getItem("userDetails"));
        setUserInfo((prev) => ({
          ...prev,
          ...details,
          token: storage.getItem("token"),
          user: storage.getItem("user"),
        }));
      }
    }
  }, [user, isLoading]);
 
  return (
    <UserContext.Provider value={{ userInfo, error, isLoading, loading, setLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useLocalUser = () => {
  return useContext(UserContext);
};
