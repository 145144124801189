export default function IconHelper({
  iconName,
  className,
  color,
  size,
  variant = "outlined",
  styles
}) {
  return (
    <span
      className={`material-symbols-outlined  ${
        variant == "filled" && "material-symbols-filled"
      } ${className} g-icon`}
      style={{ color: color, fontSize: size, ...styles }}
    >
      {iconName}
    </span>
  );
}
