import { useState, useEffect } from "react";
import axios from "axios";
import useApiLink from "../ApiLink";
import { useRouter } from "next/router";
import useDataRequest from "../../../utils/AxiosInstance";
import { useLocalUser } from "../../AuthContext/UserContext";

export function useDetails() {
  const { userInfo, loading, setLoading } = useLocalUser();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (userInfo && userInfo?.token) {
      setUserDetails((prev) => {
        return {
          ...prev,
          ...userInfo,
          token: userInfo.token,
          user: userInfo.user,
        };
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [userInfo]);

  return [userDetails, loading];
}

export const useSelectedUser = () => {
  const [selectedUser, setSelectedUser] = useState({});
  useEffect(() => {
    const storage = sessionStorage;
    if (storage.getItem("selectedUser")) {
      const details = JSON.parse(storage.getItem("selectedUser"));
      setSelectedUser((prev) => {
        return {
          ...prev,
          ...details,
        };
      });
    }
  }, []);

  return [selectedUser];
};

export const useActiveUser = () => {
  const [activeUser, setActiveUser] = useState({});
  const [userDetails] = useDetails();
  const [fullDetails] = useGlobalUser(userDetails._id);
  const [selectedUser] = useSelectedUser();

  useEffect(() => {
    if (selectedUser?.token) setActiveUser(selectedUser.user);
    else setActiveUser(fullDetails);
  }, [fullDetails, selectedUser, userDetails]);

  return [activeUser];
};

export const useAllUsers = () => {
  const ApiLink = useApiLink();
  const [userDetails] = useDetails();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiLink}/user/search-users`, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
        },
      })
      .then((res) => {
        setUsers(res.data.users);
        setLoading(false);
      })
      .catch(console.error);
  }, [userDetails, ApiLink]);

  return [users, loading];
};

export const useToken = () => {
  const [userDetails] = useDetails();
  const [selectedUser] = useSelectedUser();
  const [token, setToken] = useState("");

  useEffect(() => {
    if (selectedUser?.token) setToken(selectedUser.token);
    else setToken(userDetails.token);
  }, [userDetails, selectedUser]);

  return token;
};

export const useUserId = () => {
  const [userDetails] = useDetails();
  const [selectedUser] = useSelectedUser();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (selectedUser?.user?._id) setUserId(selectedUser.user?._id);
    else setUserId(userDetails.id);
  }, [userDetails, selectedUser]);

  return userId;
};

export function useGlobalUser(id) {
  const ApiLink = useApiLink();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [globalUser, setGlobalUser] = useState({});

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${ApiLink}/user/${id}`)
        .then((res) => {
          res.data.data ? setGlobalUser(res.data.data) : setGlobalUser({});
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setError(true);
          setLoading(false);
        });
    } else setGlobalUser({});
  }, [id, ApiLink]);

  return [globalUser, loading, error];
}

export function useCandidateDetails(id) {
  const ApiLink = useApiLink();
  const [loading, setLoading] = useState(true);
  const [candidate, setCandidate] = useState({});

  useEffect(() => {
    if (id) setLoading(true);
    axios
      .get(`${ApiLink}/candidate/candidate-profile/${id}`)
      .then((res) => {
        setCandidate(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.error(err.response));
    ``;
    //  .finally(() => );
  }, [ApiLink, id]);

  return [candidate, loading];
}

export function useJobDetails(jId) {
  const ApiLink = useApiLink();
  const [loading, setLoading] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const token = useToken();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiLink}/job/job-overview/${jId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setJobDetail(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  }, [ApiLink, jId, token]);

  return [jobDetail, loading];
}

export const useUserRights = (page) => {
  const userId = useUserId();
  const [userDetails] = useGlobalUser(userId);
  const [right, setRight] = useState("");

  useEffect(() => {
    if (!userDetails[page] || userDetails[page] == "none") setRight(false);
    else setRight(userDetails[page]);
  }, [userDetails, page]);

  return right;
};
