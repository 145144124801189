const practicalTraining = [
  "Radiological diagnostics",
  "Other imaging procedures",
  "Radiation therapy ",
  "Nuclear medicine",
  "General surgery",
  "Visceral surgery",
  "Vascular surgery",
  "Opthalmic surgery",
  "Ear surgery",
  "Nose surgery",
  "Throat surgery",
  "Neuro surgery",
  "Thoracic surgery",
  "Traumatology",
  "Orthopedics",
  "Gynaecology",
  "Urology",
  "Surgical ambulance",
  "Central sterilization",
  "Endoscopy",
  "Nursing internship",
  "Hospital internship",
];
const processExperience = [
  "Practice processes",
  "Reception services",
  "Reception technologies",
  "Call center",
  "Patient care",
  "Patient consultancy",
  "Laboratory work",
  "Administrative work",
  "Organizational work",
  "Bookkeeping",
  "Safety standards",
  "Hygiene standards",
];

const dutyExperience = [
  "Früh",
  "Spät",
  "Nacht",
  "Samstag",
  "Sonntag",
  "Wochenende",
  "Auf Abruf",
];

const expLevels = ["Einsteiger", "Fundiert", "Langjährig"];

const areasOfExpertise = [
  { id: 1, name: "Diagnostische Radiologie" },
  { id: 2, name: "Radiologie" },
  { id: 3, name: "Neuroradiologie" },
  { id: 4, name: "Strahlentherapie" },
  { id: 5, name: "Interventionelle Radiologie" },
  { id: 6, name: "Pädiatrische Radiologie" },
  { id: 7, name: "Minimalinvasive Radiologie" },
  { id: 8, name: "Radiologische Diagnostik" },
];

const featuresAndAmenities = [
  { id: 1, name: "Arbeitskleidung" },
  { id: 2, name: "Kleiderschrank" },
  { id: 3, name: "WLAN" },
  { id: 4, name: "Fernseher" },
  { id: 5, name: "Dedizierter Arbeitsbereich" },
  { id: 6, name: "Terrasse" },
  { id: 7, name: "Balkon" },
  { id: 8, name: "Raucherbereich" },
  { id: 9, name: "Außenessbereich" },
  { id: 10, name: "Obstkörbe" },
  { id: 11, name: "Getränke" },
  { id: 12, name: "Kaffeemaschine" },
  { id: 13, name: "Klimaanlage" },
  { id: 14, name: "Kostenpflichtiges Parken" },
  { id: 15, name: "Kostenloses Parken" },
  { id: 16, name: "Öffentliche Verkehrsmittel" },
  { id: 17, name: "Barrierefrei" },
  { id: 18, name: "Erdgeschoss" },
  { id: 19, name: "Erster Stock" },
  { id: 20, name: "Frische Blumen" },
  { id: 21, name: "Bilder" },
];

const rooms = [
  { id: 1, name: "Empfangsbereich" },
  { id: 2, name: "Wartezimmer" },
  { id: 3, name: "Umkleideraum" },
  { id: 4, name: "Beratungsraum" },
  { id: 5, name: "Arztzimmer" },
  { id: 6, name: "Schulungsraum" },
  { id: 7, name: "Veranstaltungsraum" },
  { id: 8, name: "Technikraum" },
  { id: 9, name: "Geräteraum" },
  { id: 10, name: "Labor" },
  { id: 11, name: "Küche" },
  { id: 12, name: "Patiententoilette" },
  { id: 13, name: "Mitarbeitertoilette" },
  { id: 14, name: "Backoffice" },
  { id: 15, name: "Serverraum" },
  { id: 16, name: "Reinigungsraum" },
  { id: 17, name: "Lagerraum" },
  { id: 18, name: "Verwaltungsbüro" },
];
const practiceRules = ["Rauchen Verboten", "Keine Haustiere", "Keine Kinder"];

const safetyItems = [
  { id: 1, name: "Rauchmelder" },
  { id: 2, name: "Erste-Hilfe-Set" },
  { id: 3, name: "Feuerlöscher" },
  { id: 4, name: "Kohlenmonoxidmelder" },
  { id: 5, name: "Alarmanlage" },
  { id: 6, name: "Notausgang" },
];
const germanLevels = ["Keine", "A1", "A2", "B1", "B2", "C1", "C2", "Fließend"];

const germanAbilityLevels = [
  { value: 0, label: "Keine" },
  { value: 13, label: "A1" },
  { value: 26, label: "A2" },
  { value: 39, label: "B1" },
  { value: 52, label: "B2" },
  { value: 65, label: "C1" },
  { value: 80, label: "C2" },
  { value: 100, label: "Fließend" },
];

const genders = ["Frau", "Herr", "Divers"];
const titles = ["Dr.", "Prof"];

const jobTitles = [
  "Geschäftsleitung",
  "Arzt",
  "Praxismanagement",
  "MTR-Leitung",
  "MTR",
  "MFA",
  "Empfang",
  "Sonstige Mitarbeitende",
  "Pensionär",
];

const curagitaJobTitles = ["Vorstand", "Leitung", "Mitarbeiter"];
const generation = [
  { id: 1, name: "Baby boomer" },
  { id: 2, name: "Slacker" },
  { id: 3, name: "Millenial " },
  { id: 4, name: "Digital native" },
  { id: 5, name: "Gen Alpha" },
];
const educationLevel = [
  { id: 1, name: "Apprenticeship" },
  { id: 2, name: "Master Diploma" },
  { id: 3, name: "Bachelor/Diplona" },
  { id: 4, name: "Internshup" },
];

const medicalDevice = [
  "Röntgen",
  "MRI",
  "CT",
  "PET/CT",
  "Mammo",
  "Sono",
  "EKG",
];
const adminTasks = [
  "Reception services",
  "Call center",
  "Patient care",
  "Patient consultancy",
  "Laboratory work",
  "Administrative work",
  "Organizational work",
  "Bookkeeping",
  "Safety measures",
  "Hygiene measures",
];

const benefits = [
  "Onboarding",
  "Online Schulung",
  "Vor-Ort-Ausbildung",
  "Babbel-Konto",
  "Teamveranstaltungen",
  "Networking Events",
  "Netzwerkveranstaltungen",
  "Feedback-Kultur",
  "Flexibles Arbeiten",
  "Auszeit",
  "Work-Life-Balance",
  "Versicherungspaket",
  "Rentenvorsorge",
  "Mitarbeiterrabatte",
  "Nahverkehrsticket",
  "Mobilitätsbudget",
  "Dienstfahrrad",
  "Firmenwagen",
  "Handyvertrag",
  "Arbeitskleidunzg",
  "Obstkorb",
  "Getränke",
  "Wohnungssuche",
  "Mietunterstützung",
  "Umzugsunterstützung",
  "Umzugsservice",
  "Gute Erreichbarkeit",
  "Moderne Ausstattung",
  "Zentrale Lage",
];

const areasOfExpertisePartner = [
  "Radiologie / Strahlentherapie",
  "Allgemeinmedizin",
  "Anästhesiologie",
  "Anatomie",
  "Arbeitsmedizin",
  "Augenheilkunde",
  "Biochemie",
  "Chirurgie",
  "Gynäkologie",
  "Hals-Nasen-Ohren-Heilkunde",
  "Hautkrankheiten",
  "Geschlechtskrankheiten",
  "Humangenetik",
  "Hygiene",
  "Umweltmedizin",
  "Innere Medizin",
  "Pädiatrie",
  "Psychiatrie",
  "Psychotherapie",
  "Physiotherapie",
  "Laboratoriumsmedizin",
  "Mikrobiologie, Virologie & Infektionsepidemiologie",
  "Chirurgie",
  "Neurologie",
  "Nuklearmedizin",
  "Öffentlicher Gesundheitsdienst",
  "Pathologie",
  "Pharmakologie",
  "Phoniatrie & Pädaudiologie",
  "Physikalische und Rehabilitative Medizin",
  "Physiologie",
  "Psychosomatische Medizin",
  "Radiologie",
  "Strahlentherapie",
  "Rechtsmedizin",
  "Transfusionsmedizin",
  "Urologie",
  "Pflege",
];

const serviceOffering = [
  "Beratung",
  "Beschaffung",
  "Vermittlung",
  "Interviews",
  "Arbeitsvertrag",
  "Dokumente",
  "Sprachkurse",
  "Sprachschule",
  "Behördliche Angelegenheiten",
  "Einarbeitung",
  "Zahlungen",
  "Integration",
  "Post-Qualifikationen",
  "Umzug",
  "Callcenter",
  "Support-Hotline",
  "Projektmanagement",
];

const categories = ["MTR", "MFA", "MTL", "CTA"];

const jobCategories = ["MTR", "MFA", "MTL", "CTA"];

const blogTags = [
  "CurAcademy",
  "Fachkräftemangel",
  "Fachbeirat",
  "Gerätemanagement",
  "Radiologienetztag",
  "MTR",
  "DSGVO",
  "Digitalisierung",
  "MRT",
  "GKV",
  "Gesundheitspolitik",
  "MVZ",
  "Vollversammlungen",
  "Fachliteratur",
  "Covid-19",
  "Radiologienetz",
  "Curagita",
  "Praxisberatung",
  "Praxisentwicklung",
  "GOÄ",
];

const articleSections = [
  {
    title: "Radiologie",
    list: [
      "Allgemein",
      "Politik",
      "Wirtschaft",
      "Gesellschaft",
      "Technologie",
      "Lobbying",
      "Ausblick",
    ],
  },
  {
    title: "CuraNetz",
    list: [
      "Allgemein",
      "Mitgliedschaft",
      "Verbundprojekte",
      "Mitgliederverzeichnis",
    ],
  },
  {
    title: "CuraShop",
    list: ["Allgemein", "Customer Service", "Logistik"],
  },
  {
    title: "CuraConsult",
    list: [
      "Allgemein",
      "Strategie & Wachstum",
      "Personal & Organisation",
      "Kostenmanagement",
      "Restrukturierung",
      "Workflow",
      "Konflikte",
    ],
  },
  {
    title: "CuraFee",
    list: [
      "Allgemein",
      "GKV Abrechnung",
      "CuraFee",
      "PKV- & BG-Abrechnung",
      "Zulassung & Regulatorik",
    ],
  },
  {
    title: "CuraaTech",
    list: [
      "Allgemein",
      "Medizinische Geräte",
      "Geräteservice",
      "Praxisveränderung & -umbau",
    ],
  },
  {
    title: "CuraScan",
    list: ["Allgemein", "Implementierung & Support", "Scanning", "Coaching"],
  },
  {
    title: "CuraTalent",
    list: [
      "Allgemein",
      "Personal- & Dienstplanung",
      "Recruiting",
      "Anerkennung",
      "Begleitung",
      "Integration",
      "Inländisches Personal",
    ],
  },

  {
    title: "CuraProtect",
    list: [
      "Allgemein",
      "Arbeitsschutz",
      "Datenschutz",
      "Strahlenschutz",
      "Qualitätsmanagement",
    ],
  },
  {
    title: "CuraIT",
    list: [
      "Allgemein",
      "CuraAid",
      "CuraSAFE",
      "CuraMATIK",
      "CuraSEC",
      "CuraWatch",
    ],
  },
  {
    title: "CuraCommunication",
    list: ["Allgemein", "Online Marketing", "Strategie"],
  },
  {
    title: "Curagita",
    list: ["Allgemein", "Über uns", "Investor Relations", "Karriere bei uns"],
  },
  {
    title: "Digital",
    list: [
      "Allgemein",
      "Erste Schritte",
      "Dein Nutzerkonto",
      "Sicherheit & Barrierefreiheit",
    ],
  },
];

const services = [
  "Radiologie",
  "CuraNetz",
  "CuraShop",
  "CuraConsult",
  "CuraFee",
  "CuraTech",
  "CuraScan",
  "CuraTalent",
  "CuraProtect",
  "CuraIT",
  "CuraCommunication",
  "Curagita",
  "Digital",
];

const features = [
  "CuraFinance",
  "CuraConsult",
  "CuraTek",
  "CuraHr",
  "CuraTalent",
  "CuraProtect",
  "CuraShop",
  "CuraIT",
  "CuraNetz",
  "Know-How",
];
const tools = [
  {
    name: "Messages",
    iconName: "chat_bubble",
  },
  {
    name: "CuraFinance",
    iconName: "bar_chart_4_bars",
  },
  {
    name: "CuraConsult",
    iconName: "partner_exchange",
  },
  {
    name: "CuraTek",
    iconName: "monitor_heart",
  },
  {
    name: "CuraHr",
    iconName: "hub",
  },
  {
    name: "CuraTalent",
    iconName: "person_play",
  },
  {
    name: "CuraProtect",
    iconName: "verified_user",
  },
  {
    name: "CuraShop",
    iconName: "shopping_cart",
  },
  {
    name: "CuraIt",
    iconName: "desktop_windows",
  },
  {
    name: "CuraNetz",
    iconName: "diversity_3",
  },
  {
    name: "Know_How",
    iconName: "import_contacts",
  },
];

const toolsArr = [
  "MRT",
  "CT",
  "PET-CT",
  "Röntgen",
  "Mammographie",
  "Nuklearmedizin",
  "Strahlentherapie",
  "Kontrastmittel",
];
const deviceBrands = ["siemens", "philips", "ge", "canon", "toshiba"];

const platforms = ["LinkedIn", "Xing", "Kununu", "Facebook", "Instagram"];

const recruiting = [
  "Bewerbungsdokumente",
  "Talentsuche",
  "Talentprüfung",
  "Talentvorstellung",
  "Arbeitsverträge",
  "Ersatztalent",
];

const anerkennung = [
  "Antragsstellung",
  "Antragsprozess",
  "Visumsantrag",
  "Sprachkurse",
  "Vorbereitungskurse",
];

const ankuft = [
  "Umzug",
  "Wohnung",
  "Örtliche Anmeldung",
  "Bank, KV, SV-Nummer",
  "Onboarding",
];

const anpassungen = [
  "Weiterbildungen",
  "Fördermittelantrag",
  "Sprachkurse",
  "Integration",
  "Talentnetz",
];

const departments = [
  "CuraFinance",
  "CuraFee",
  "CuraConsult",
  "CuraProtect",
  "CuraScan",
  "CuraTalent",
  "CuraTek",
  "CuraIT",
  "CuraShop",
  "CuraCommunication",
  "Netzaustausch & CurAcademy",
  "Netzmanagement",
  "Personal",
  "Digitalisierung",
  "Office Management",
  "Assistenz",
  "Management",
];

module.exports = {
  categories,
  benefits,
  adminTasks,
  medicalDevice,
  generation,
  areasOfExpertise,
  germanAbilityLevels,
  genders,
  rooms,
  featuresAndAmenities,
  practiceRules,
  safetyItems,
  educationLevel,
  processExperience,
  dutyExperience,
  areasOfExpertisePartner,
  serviceOffering,
  jobCategories,
  blogTags,
  articleSections,
  services,
  features,
  toolsArr,
  titles,
  jobTitles,
  platforms,
  germanLevels,
  deviceBrands,
  recruiting,
  anerkennung,
  ankuft,
  anpassungen,
  expLevels,
  departments,
  tools,
  curagitaJobTitles
};
