import React, { useContext } from "react";
import "../public/bootstrap.css";
import "../styles/globals.css";
import "../public/index.scss";
import "../public/general.scss";
import "../public/new.scss";
import "../public/media-queries.scss";
import "../public/Slider.scss";
import "../public/hover.css";
import "../public/animations.css";
import "../public/global-icons.css";
import "react-phone-input-2/lib/bootstrap.css";
import Head from "next/head";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timegrid/main.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import ReactGA from "react-ga";
import { SocketContextProvider } from "../src/Messaging/SocketContext";
import Script from "next/script";
import * as ga from "../analytics";
import { useRouter } from "next/router";
import { UserProvider } from "@auth0/nextjs-auth0";
import NavBar from "../src/reusable/NavBar";
import { LocalUserProvider } from "../src/AuthContext/UserContext";
config.autoAddCss = false;

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const showNav = Component.showNav !== false;
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    ga.initGA();
    ga.logPageView();
    const handleRouteChange = (url) => {
      ga.logPageView();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up the event listener
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Radiologienetz</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <Script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></Script>
      <UserProvider>
        <LocalUserProvider>
          <SocketContextProvider>
            {showNav && <NavBar />}
            <Component {...pageProps} />
          </SocketContextProvider>
        </LocalUserProvider>
      </UserProvider>
    </>
  );
}
