import React, { useEffect, useState } from "react";

import style from "../../../styles/Header.module.scss";
import { ClickAwayListener } from "@mui/base";
import { useDetails, useAllUsers } from "../../reusable/CustomHooks";
import { useRouter } from "next/router";
import useApiLink from "../../reusable/ApiLink";
import axios from "axios";
import Loader from "../../reusable/Loader";
import useDataRequest from "../../../utils/AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SearchUser({ allowOnly = "all" }) {
  const router = useRouter();
  const ApiLink = useApiLink();
  const [userDetails] = useDetails();
  const { data } = useDataRequest("user/admin-get-all-users");
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([
    {
      name: "Customer",
      accountType: "customer",
    },
    {
      name: "Partner",
      accountType: "partner",
    },
    {
      name: "Candidate",
      accountType: "candidate",
    },
  ]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const Clear = () => {
    sessionStorage.removeItem("selectedUser");
    sessionStorage.removeItem("token");
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("selectedUser");
    if (storedUser) {
      setSelectedUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiLink}/admin/search-users`, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
        },
      })
      .then((res) => {
        setUsers(res.data.usersWithTokens);
        setOptions(res.data.usersWithTokens);
        setLoading(false);
      })
      .catch((err) => {
        // console.error(err.response?.data);
        setLoading(false);
      });
  }, [ApiLink, userDetails]);

  useEffect(() => {
    allowOnly == "all"
      ? setUsers(options)
      : setUsers(
          options.filter(
            (obj) => obj.user?.accountType === allowOnly.toUpperCase()
          )
        );
  }, [allowOnly, options]);

  useEffect(() => {
    if (Object.keys(selectedUser).length !== 0) {
      sessionStorage.setItem("selectedUser", JSON.stringify(selectedUser));
      sessionStorage.setItem("token", selectedUser.token);
    }

    setSelected(
      !selectedUser.user?.accountName
        ? selectedUser.user?.vorname
        : selectedUser.user?.accountName
    );
  }, [selectedUser]);

  const filterUsers = (value) => {
    let filteredResults;
    if (value !== "") {
      users.some((obj) => obj.user?.type == "subuser")
        ? (filteredResults = options.filter((item) => {
            return (
              item.user?.vorname.toLowerCase().includes(value) ||
              item.user?.nachname.toLowerCase().includes(value)
            );
          }))
        : (filteredResults = options.filter((item) => {
            return item.user?.accountName?.toLowerCase().includes(value);
          }));

      setUsers(filteredResults);
    } else setUsers(options);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={style.navBtnWrapper}>
        <button className={style.userDetails} onClick={() => setOpen(!isOpen)}>
          {selected || "Account"}
          <FontAwesomeIcon icon={["fas", "chevron-down"]} />
        </button>
        {isOpen && (
          <div
            className={style.navDropdown}
            style={{ right: 24, left: "auto", top: "40px", minWidth: "125px" }}
          >
            {loading ? (
              <p>Wird geladen.. </p>
            ) : (
              <>
                <p
                  onClick={() => {
                    Clear();
                    router.reload();
                  }}
                >
                  None
                </p>
                {users.map((el, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      !el.user?.accounName
                        ? setSelected(el.user.vorname)
                        : setSelected(el.user.accountName);
                      setSelectedUser(el);
                      router.reload();
                    }}
                  >
                    {!el.user?.accountName
                      ? el.user?.vorname
                      : el.user?.accountName}
                  </p>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
