import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Snackbar } from "@mui/material";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import style from "../../styles/documents.module.scss";
import useApiLink from "../reusable/ApiLink";

let token;
if (typeof window !== "undefined") {
  if (window.location.pathname.includes("admin")) {
    token = sessionStorage.getItem("token");
  } else {
    token = localStorage.getItem("token");
  }
}

let server = process.env.NEXT_PUBLIC_BASE_URL;

const SOCKET_SERVER = server;
const AUTH_TOKEN = token;
const socket = io(SOCKET_SERVER, {
  auth: { Authorization: `Bearer ${AUTH_TOKEN}` },
});

library.add(fas, fab);

const SocketContext = createContext(null);

export const SocketContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [selected, setSelected] = useState({});
  const ApiLink = useApiLink();
  const [open, setOpen] = useState(false);
  const [latest, setLatest] = useState({});
  const [user, setUser] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [showList, setShowList] = useState(false);
  const [userDetails, setUserDetails] = useState({})
 

  useEffect(() => {
    const storage = window.localStorage;

    setTimeout(() => {
      if (storage.getItem("userDetails") && storage.getItem("token")) {
        const details = JSON.parse(storage.getItem("userDetails"));
        setUserDetails((prev) => {
          return {
            ...prev,
            ...details,
            token: storage.getItem("token"),
            user: storage.getItem("user"),
          };
        });
      }
    }, 2000);

  }, []);

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
      setShowList(true);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = (
    <FontAwesomeIcon icon={["fa", "times"]} onClick={handleClose} />
  );

  // conversation => messages
  useEffect(() => {
    socket.on("connect", () => {
      socket
        .emit("authenticate", { token: AUTH_TOKEN })
        .on("authenticated", () => {
          console.log("CONNECTED")
        });
    });

    socket.on(
      "NEW_MESSAGE_EVENT_RECEIVED",
      ({ conversation, messages, message }) => {
        setSelected({
          conversation,
          messages,
        });
        setLatest(() => message);
        if (message.receiver === userDetails.id) {
          getUser(message.sender)
            .then((response) => {
              setUser(() => response.data.data);
              setOpen(true);
              console.log(latest.content);
            })
            .catch((error) => {
              console.error(error.response);
            });
        }
      }
    );

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("NEW_MESSAGE_EVENT_RECEIVED");
    };
  }, [userDetails]);

  // get userDetails from id
  const getUser = (id) => {
    return axios.get(`${ApiLink}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
        "Content-Type": "application/json",
      },
    });
  };

  // get all conversations
  const getAllConversations = () => {
    axios
      .get(`${ApiLink}/conversation`, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setConversations(response.data.conversations);
        setLoading(false);
        if (!response.ok) {
          setLoading(false);
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        console.error(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllConversations();
  }, [userDetails, selected, reload, ApiLink]);

  const getAllMessages = (id) => {
    axios
      .get(`${ApiLink}/conversation/${id}`, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSelected(response.data);
        if (isMobile) {
          setShowList(false);
          setShowMsg(true);
        }
        if (!response.ok) {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const createConversation = (id) => {
    const body = {
      senderId: userDetails.id,
      receiverId: id,
    };
    axios
      .post(
        `${ApiLink}/conversation/create-conversation`,
        JSON.stringify(body),
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setReload(!reload);
        getAllMessages(response.data.data.id);
        if (!response.ok) {
          throw new Error(response.message);
        }
        return response.data.data.id;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const trimText = (str) => {
    if (str && str?.length > 50) {
      return str?.slice(0, 50) + "...";
    } else {
      return str;
    }
  };

  const def =
    "https://cameafile.s3.eu-central-1.amazonaws.com/camea/istockphoto-1324041343-612x612.jpg";

  const alertMessage = (
    <div
      className={
        "d-flex align-items-center py-2 px-2 rounded position-relative"
      }
    >
      <div
        className={style.cImg + " " + "mr-3"}
        style={{
          backgroundImage: `url(${user?.imgUrl || def})`,
          borderRadius: "50%",
          width: "40px",
          height: "40px",
        }}
      ></div>
      <div>
        <p className="receiver-name" style={{ fontSize: "14px" }}>
          {user?.firstName + " " + user?.lastName}
        </p>
        <p style={{ fontSize: "12px" }}>{trimText(latest?.content)}</p>
      </div>
    </div>
  );

  return (
    <SocketContext.Provider
      value={{
        socket,
        getAllMessages,
        conversations,
        createConversation,
        userDetails,
        reload,
        setReload,
        selected,
        setSelected,
        setLoading,
        setConversations,
        setShowMsg,
        setShowList,
        showList,
        showMsg,
        isMobile,
        setIsMobile,
        loading,
      }}
    >
      {children}
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message={alertMessage}
        action={action}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
