import { BeatLoader } from "react-spinners";

export default function Loader({ type, variant }) {

  return (
    <div
      className={`loaderWrapper ${variant == "welcome" ? "f-loader" : null}`}
    >
      <div className="loader">
        <BeatLoader size={25} color="var(--color-blue)" />
      </div>
    </div>
  );
}
