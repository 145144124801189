import { useState } from "react";
import style from "../../../styles/Header.module.scss";
import { useLinkPrefix } from "../CustomHooks/others";
import { ActiveLineIcon } from "./components";
import { useRouter } from "next/router";

export default function Navigation({ nav, navRef, styles, tool }) {
  const router = useRouter();
  const prefix = useLinkPrefix();
  const [hovering, setHovering] = useState(false);

  return (
    <div
      ref={navRef}
      className={style.navBtnWrapper}
      onClick={() => router.push(`${prefix}/${nav.list[0]?.specifiedPath ? nav.list[0]?.specifiedPath : nav.list[0]?.path}`)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <ActiveLineIcon
        color={nav.title == tool ? "#009FE3" : "#DCDCDC"}
        className={
          hovering ? "visible" : nav.title == tool ? "visible" : "invisible"
        }
      />
      <button
        className={style.navBtn}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
          <img
            src={`/icons/${
              hovering || nav.title == tool
                ? `${nav?.toolName === "Netzaustausch & CurAcademy" ? "curanetz" : nav.toolName.toLowerCase()}_active`
                : (nav?.toolName === "Netzaustausch & CurAcademy" ? "curanetz" : nav.toolName.toLowerCase())
            }.svg`}
          />
        
        <p className={style.navText}> {nav.title}</p>
      </button>
    </div>
  );
}
