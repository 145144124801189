import { useRouter } from "next/router";
import { services } from "../../../utils/GeneralData";
import { useAuthUser, useUserType } from "../CustomHooks/others";
import { useEffect, useState } from "react";

export default function useRoutes(userDetails = {}) {
  const router = useRouter();
  const { id } = router.query; //for planner
  const user = useAuthUser();
  const loggedInUser = useUserType();

  const settingsRoutes = [
    {
      title: "Profil",
      path: `konto`,
      //  specifiedPath:
      //  userDetails.type == "subuser"
      //  ? `konto?profileId=${userDetails._id}`
      // : "konto",
    },
    {
      title: user == "customer" ? "Praxis" : "Firma",
      path: "org",
      specifiedPath: `konto/org`,
    },
    {
      title: "Berechtigungen",
      path: "berechtigungen",
      specifiedPath: "/konto/berechtigungen",
    },
    {
      title: "Benachrichtigungen",
      path: "benachrichtigungen",
      specifiedPath: "/konto/benachrichtigungen",
    },
  ].filter((item) => item !== null);

  const defaultRoutes = [
    {
      title: "Finance",
      toolName: "CuraFinance",
      iconName: "bar_chart_4_bars",
      isTool: true,
      path: "curafinance",
      toolAssistant: {
        name: "Bernd Nagel",
        email: "ben@curagita.com",
        phone: "+49 6221 5025-160",
        img: "Bernd-Nagel.jpg",
      },
      list: [
        {
          title: "DCF-Rechnung",
          path: "curafinance",
        },
      ],
    },
    {
      title: "Consult",
      toolName: "CuraConsult",
      isTool: true,
      iconName: "partner_exchange",
      path: "curavalue",
      toolAssistant: {
        name: "Bernd Nagel",
        email: "ben@curagita.com",
        phone: "+49 6221 5025-160",
        img: "Bernd-Nagel.jpg",
      },
      list: [
        {
          title: "CuraValue",
          path: "curavalue",
        },
        {
          title: "Praxisprofil",
          path: "https://web.umfrageonline.com/s/interaktives_praxisprofil",
          external: true,
        },
      ],
    },
    {
      title: "Tech",
      toolName: "CuraTech",
      isTool: true,
      path: "deratek",
      iconName: "monitor_heart",
      icon: "/icons/tech.svg",
      toolAssistant: {
        name: "Andrea Salwat",
        email: "sal@curagita.com",
        phone: "+49 6221 5025-568",
        img: "Andrea-Salwat.jpg",
      },
      list: [
        // {
        //   title: "Report",
        //   path: "curatech",
        // },
        {
          title: "Vergleich",
          path: "deratek",
          list: [
            {
              title: "Alle Geräte",
              path: "deratek",
            },
            {
              title: "Gerätevergleich",
              path: "comparison",
              specifiedPath: "deratek/comparison",
            },
          ],
        },
      ],
    },
    {
      title: "IT",
      toolName: "CuraIT",
      isTool: true,
      path: "cura-watch",
      list: [
        {
          title: "CuraWatch",
          path: "cura-watch",
          toolAssistant: {
            name: "Jean-Marc Lempp",
            email: "jml@curagita.com",
            phone: "+49 6221 5025-550",
            img: "Jean-Marc-Lempp.png",
          },
        },
        {
          title: "Tickets",
          path: "tickets",
          toolAssistant: {
            name: "Jean-Marc Lempp",
            email: "jml@curagita.com",
            phone: "+49 6221 5025-550",
            img: "Jean-Marc-Lempp.png",
          },
        },
      ],
    },
    {
      title: "Netz",
      toolName: "Netzaustausch & CurAcademy",
      iconName: "diversity_3",
      path: "events",
      isTool: true,
      list: [
        {
          title: "Events & Fortbildungen",
          path: "events",
          toolAssistant: {
            name: "Anna Scherger",
            email: "ans@curagita.com",
            phone: "+49 6221 5025-410",
            img: "Anna-Scherger.png",
          },
          list: [
            {
              title: "Alle Events & Fortbildungen",
              path: "events",
            },
            user === "admin" || user === "curagita"
              ? {
                  title: "Teilnehmende",
                  path: "teilnehmende",
                  specifiedPath: "events/teilnehmende",
                }
              : null,
          ].filter((item) => item !== null),
        },
        {
          title: "E-Learning",
          path: "e-learning",
          specifiedPath: "e-learning/videos",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
        },
        {
          title: "Verbundvorteile",
          path: "verbundvorteile",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
        },
      ],
    },
    {
      title: "Talent",
      toolName: "CuraTalent",
      isTool: true,
      path: "search",
      icon: "/icons/talent.svg",
      list: [
        {
          title: "Profile",
          path: "search",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
          list: [
            {
              title: "Alle Talente",
              path: "search",
            },
            ...(user !== "curagita" || user !== "candidate"
              ? [
                  {
                    title: "Favoriten",
                    path: "favourites",
                  },
                ]
              : []),
          ],
        },
        {
          title: "Buchungen",
          path: "bookings",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
        },
        {
          title: "Planner",
          path: "planner",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
          list: [
            {
              title: "Prozess",
              path: id
                ? `planner?id=${id}`
                : user == "candidate"
                ? `planner?id=${userDetails._id}`
                : "planner",
            },
            {
              title: "Sprache",
              path: "language",
              specifiedPath: id
                ? `planner/language?id=${id}`
                : user == "candidate"
                ? `planner/language?id=${userDetails._id}`
                : "planner/language",
            },
          ],
        },
        ...(user == "customer" || user == "admin"
          ? [
              {
                title: "Stellen",
                path: "jobs",
                toolAssistant: {
                  name: "Luisa Schmidt-Tophoff",
                  email: "lst@curagita.com",
                  phone: "+49 152 0152 5200",
                  img: "Luisa-Schmidt-Tophoff.jpg",
                },
                list: [
                  {
                    title: "Übersicht",
                    path: "jobs",
                  },
                  {
                    title: "Tabelle",
                    path: "jobs",
                    specifiedPath: "jobs/table",
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      title: "Know-how",
      toolName: "Know-how",
      iconName: "import_contacts",
      path: "know-how",
      isTool: true,
      toolAssistant: {
        name: "Luisa Schmidt-Tophoff",
        email: "lst@curagita.com",
        phone: "+49 152 0152 5200",
        img: "Luisa-Schmidt-Tophoff.jpg",
      },
      list: [
        {
          title: "Alle Artikel",
          path: "know-how",
          specifiedPath: "know-how/articles",
          list: [
            {
              title: "Alle Artikel",
              path: "know-how/articles",
              specifiedPath: `know-how/articles`,
            },
            ...services.map((x) => ({
              title: x,
              path: x,
              specifiedPath: `know-how/articles/${x}`,
            })),
          ],
        },
        {
          title: "Favoriten",
          path: "favourite-articles",
          specifiedPath: "know-how/articles/favourites",
        },
        user === "admin" || user === "curagita"
          ? {
              title: "Admin",
              path: "articles-table",
              specifiedPath: "know-how/articles/table",
            }
          : null,
      ].filter((item) => item !== null),
    },
    {
      title: "Konto",
      toolName: "Konto",
      isTool: false,
      list: settingsRoutes,
    },
  ];

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (loggedInUser == "curagita" || loggedInUser == "admin") {
      setRoutes([...defaultRoutes, settingsRoutes]);
      return;
    }
    const newArr = defaultRoutes.filter((item) => {
      const a = userDetails[item.toolName?.toLowerCase()];
      return a !== "none" && a !== undefined;
    });
    setRoutes([...newArr, settingsRoutes]);

  }, [loggedInUser, userDetails]);

  return { routes };
}
