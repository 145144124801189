import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import Link from 'next/link'
import React, { Children } from 'react'

const NextNavLink = ({ children, activeClassName, ...props }) => {
  const { asPath, pathname } = useRouter()
  const child = Children.only(children)
  const childClassName = child.props.className || ''

  const className =
  decodeURIComponent(asPath) === props.href || decodeURIComponent(asPath) === props.as 
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName

      
  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
        style: (asPath === pathname + props.href) ? null : null
      })}
    </Link>
  )
}

NextNavLink.propTypes = {
  activeClassName: PropTypes.string.isRequired,
}

export default NextNavLink