import React, { useEffect, useState, useRef } from "react";
import NextNavLink from "../NavLink";
import { useRouter } from "next/router";
import Loader from "../Loader";
import style from "../../../styles/Header.module.scss";
import {
  useActiveUser,
  useDetails,
  useGlobalUser,
  useSelectedUser,
  useToken,
  useUserId,
} from "../CustomHooks";
import { useAuthUser, useLinkPrefix, useUserType } from "../CustomHooks/others";
import Navigation from "./Navigation";
import { Person } from "@mui/icons-material";
import useRoutes from "./routes";
import SearchUser from "./SearchUser";
import { ClickAwayListener } from "@material-ui/core";
import IconHelper from "../IconMaterial";
import { NavItem } from "./components";
import { useUser } from "@auth0/nextjs-auth0";
import useApiLink from "../ApiLink";
import { useLocalUser } from "../../AuthContext/UserContext";

const getToolName = (path, routes = []) => {
  for (let item of routes) {
    if (item.path === path) {
      return item.title;
    }
    if (item.list) {
      for (let subItem of item.list) {
        if (subItem.path === path) {
          return item.title;
        }
        if (subItem.list) {
          for (let subItemSub of subItem.list) {
            if (subItemSub.path === path) {
              return item.title;
            }
          }
        }
      }
    }
  }
  return null;
};

const getToolAssistant = (path, routes = [], parentListItem, childListItem) => {
  for (const item of routes) {
    if (item.path === path && item.toolAssistant) {
      return item.toolAssistant;
    }
    const found = item?.list?.find(
      (subItem) => subItem.path === path && subItem.toolAssistant
    );
    if (found) {
      return found.toolAssistant;
    }
  }
  return childListItem?.toolAssistant || parentListItem?.toolAssistant;
};

const getLastPathSegment = (path, userType) => {
  const segments = path.split("/").filter((segment) => segment !== "");

  if (path.includes("/planner/language")) return "language";
  if (path.includes("/planner")) return "planner";
  if (path.includes("/profile/[profileId]")) return "search";
  if (path.includes("/deratek/comparison")) return "comparison";
  if (path.includes("/events/teilnehmende")) return "teilnehmende";
  if (path.includes("/konto/form")) return "konto";
  if (path.includes("/konto/org")) return "org";
  if (path.includes("/konto/berechtigungen")) return "berechtigungen";
  if (path.includes("/konto/benachrichtigungen")) return "benachrichtigungen";
  if (path.includes("/know-how/articles/favourites"))
    return "favourite-articles";
  if (path.includes("/know-how/articles/table")) return "articles-table";

  if (!userType) {
    return segments[0] || null;
  }
  const formattedUserType = userType.startsWith("/")
    ? userType.slice(1)
    : userType;
  const userTypeIndex = segments.indexOf(formattedUserType);
  if (userTypeIndex !== -1 && userTypeIndex < segments.length - 1) {
    return segments[userTypeIndex + 1];
  }
  return null;
};

const getNavItemFromToolName = (routes, toolName) => {
  let item = routes?.filter((x) => x?.title === toolName);
  return item[0];
};

const getSubNavItemFromNavItem = (routes, navItem) => {
  let item = routes?.filter((x) => x?.title === navItem);
  return item[0];
};

const getToolSubTitle = (path, navItem) => {
  let x = navItem?.list || [];
  for (let item of x) {
    if (item.path === path) {
      return item.title;
    }
    if (item.list) {
      for (let subItem of item.list) {
        if (subItem.path === path) {
          return item.title;
        }
      }
    }
  }
  return null;
};

export default function NavBar({}) {
  const router = useRouter();
  const { user: authUser, error, isLoading } = useUser();
  const id = useUserId();
  const { userInfo, setUserInfo } = useLocalUser();
  const userType = useUserType();
  const [activeUser] = useActiveUser()
  const ApiLink = useApiLink();
  const [selectedUser] = useSelectedUser();
  const [userDeets, loadingInfo] = useDetails();
  const [userDetails] = useGlobalUser(userDeets._id);
  const [loading, setLoading] = useState(false);
  const prefix = useLinkPrefix();
  const navRefs = useRef([]);
  const [dropdownPositions, setDropdownPositions] = useState({});
  const { routes } = useRoutes(userDetails);
  const [open, setOpen] = useState(false);
  const { pathname } = useRouter();
  const currentPath = getLastPathSegment(pathname, prefix);
  const tool = getToolName(currentPath, routes);
  const [openContact, setOpenContact] = useState(false);
  let activeNav = getNavItemFromToolName(routes, tool);
  let currentToolSubtitle = getToolSubTitle(currentPath, activeNav);
  let activeSubNav = getSubNavItemFromNavItem(
    activeNav?.list || [],
    currentToolSubtitle
  );
  let currentToolSubtitleSub = getToolSubTitle(currentPath, activeSubNav);
  const toolAssistant =
    getToolAssistant(currentPath, routes, activeNav, activeSubNav) || {};

  const logOut = async () => {
    setLoading(true);

    window.localStorage.clear();
    window.sessionStorage.clear();

    await router.push("/api/auth/logout");

    setUserInfo(null);
    setLoading(false);
  };

  const loggedInUser = activeUser.accountType?.toLowerCase();

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      const newPositions = {};

      navRefs.current.forEach((navItem, index) => {
        if (navItem) {
          const rect = navItem.getBoundingClientRect();
          if (rect.bottom > viewportHeight) {
            newPositions[index] = {
              top: "auto",
              bottom: 0,
            };
          } else {
            newPositions[index] = {
              top: rect.top,
              bottom: "auto",
            };
          }
        }
      });

      setDropdownPositions(newPositions);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [routes]);

  return (
    <>
      {loading ? <Loader variant="welcome" /> : null}
      <>
        <div className={style.logoWrapper}>
          <img alt="logo" src="/curagita-logo.svg" className={style.logo} />
        </div>
        <nav className={style.navWrapper}>
          <div className="d-flex align-items-center">
            <div className={style.toolName}>
              {activeNav && activeNav?.isTool && (
                <img
                  src={`/icons/${
                    activeNav?.toolName === "Netzaustausch & CurAcademy"
                      ? "curanetz"
                      : activeNav?.toolName.toLowerCase()
                  }.svg`}
                />
              )}
              <h1>{activeNav?.toolName}</h1>
            </div>

            <div className={style.toolNavItems}>
              {activeNav?.list.map((listItem, idx) => (
                <NavItem
                  listItem={listItem}
                  currentToolSubtitle={currentToolSubtitle}
                  key={idx}
                  activeSubNav={activeSubNav}
                  currentToolSubtitleSub={currentToolSubtitleSub}
                />
              ))}
            </div>
          </div>

          <div className="d-flex align-items-center">
            {userType == "admin" || userType == "curagita" ? (
              <SearchUser />
            ) : (
              <p
                className="px-4 fw-500"
                style={{ color: "var(--curagita-blue)" }}
              >
                {userDetails.accountName ??
                  `${userDetails.vorname ?? ""} ${
                    userDetails.nachname ?? ""
                  }`}{" "}
              </p>
            )}
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div className={style.navBtnWrapper}>
                <button
                  className={style.userIcon}
                  onClick={() => setOpen(!open)}
                >
                  <Person
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "var(--curagita-blue)",
                    }}
                  />
                </button>
                {open && (
                  <div
                    className={style.navDropdown}
                    style={{ right: 0, left: "auto", top: "48px" }}
                  >
                    <NextNavLink
                      href={
                        loggedInUser == "admin"
                          ? `${prefix}/konto/berechtigungen`
                          : activeUser.type == "subuser"
                          ? `${prefix}/konto`
                          : `${prefix}/konto/org`
                      }
                      className={style.navLink}
                    >
                      <a>Konto</a>
                    </NextNavLink>

                    <button className={style.navLink} onClick={logOut}>
                      Abmelden
                    </button>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </nav>

        <nav className={style.sideNav}>
          {routes
            ?.filter((x) => x?.isTool === true)
            .map((path, index) => (
              <Navigation
                nav={path}
                key={index}
                index={index}
                navRef={(el) => (navRefs.current[index] = el)}
                styles={dropdownPositions[index]}
                tool={tool}
              />
            ))}
        </nav>

        {activeNav?.isTool && (
          <ClickAwayListener onClickAway={() => setOpenContact(false)}>
            <div className={style.contactButtonWrapper}>
              <button
                className={`${style.contactButton} ${
                  openContact ? style.contactBtnActive : null
                }`}
                onClick={() => setOpenContact(!openContact)}
              >
                <IconHelper iconName={"support_agent"} />
              </button>

              {toolAssistant && openContact ? (
                <div className={style.contactPopup}>
                  <div className={style.contactImg}>
                    <img src={`/toolsAssistants/${toolAssistant.img}`} />
                  </div>
                  <div className={style.contactDetails}>
                    <h3 className={style.name}>{toolAssistant.name}</h3>
                    <div className={style.contactInfo}>
                      <div>
                        <IconHelper iconName={"mail"} />
                        <a href={`mailto:${toolAssistant.email}`}>
                          {toolAssistant.email}
                        </a>
                      </div>
                      <div>
                        <IconHelper iconName={"call"} />
                        <a href={`tel:${toolAssistant.phone}`}>
                          {toolAssistant.phone}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        )}
      </>
    </>
  );
}
