import { ClickAwayListener } from "@material-ui/core";
import style from "../../../../styles/Header.module.scss";
import NextNavLink from "../../NavLink";
import { useState } from "react";
import { useLinkPrefix } from "../../CustomHooks/others";

export const NavItem = ({
  listItem,
  currentToolSubtitle,
  activeSubNav,
  currentToolSubtitleSub,
}) => {
  const [open, setOpen] = useState(false);
  const prefix = useLinkPrefix();

  return !listItem?.list ? (
    listItem.external ? (
      <a href={listItem.path} target="_blank" rel="noreferrer">
        {listItem.title}
      </a>
    ) : (
      <NextNavLink
        href={`${prefix}/${
          listItem.specifiedPath ? listItem.specifiedPath : listItem.path
        }`}
        className={style.navLink}
        activeClassName={style.activeLink}
      >
        <a
          className={
            currentToolSubtitle?.toLowerCase() === listItem.title.toLowerCase()
              ? style.active
              : ""
          }
        >
          {listItem.title}
        </a>
      </NextNavLink>
    )
  ) : (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={style.dropdownNavItem}>
        <a
          className={
            "d-flex align-items-center justify-content-between" +
            " " +
            (currentToolSubtitle === listItem.title ? style.active : "") +
            " " +
            (open && style.openNavDropdown)
          }
          onClick={() => setOpen(!open)}
        >
          {listItem.title}
          <span className="material-symbols-outlined text-blue ml-2">
            keyboard_arrow_down
          </span>
        </a>
        {open && (
          <div
            className={style.navDropdown}
            style={{ left: 0, top: "55px", minWidth: "100%" }}
            onClick={() => setOpen(false)}
          >
            {listItem?.list.map((item, i) => (
              <NextNavLink
                href={`${prefix}/${
                  item.specifiedPath ? item.specifiedPath : item.path
                }`}
                className={style.navLink}
                activeClassName={style.activeLink}
                key={i}
              >
                <a
                  className={
                    currentToolSubtitleSub === item.title
                      ? style.activeSubNav
                      : ""
                  }
                >
                  {console.log(currentToolSubtitleSub, item.title)}
                  {item.title}
                </a>
              </NextNavLink>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
